:root {
    --main-margin: 10vw;
}

body {
    margin: 0px;
    font-family: Arial;
    font-size: .9em;
    color: #353535;
}

.navigation-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid lightgray;
    padding: 20px var(--main-margin);
}

.navigation-brand img {
    height: 30px;
}

.navigation-links a {
    margin-left: 30px;
    text-decoration: none;
    color: #0298db;
}

.page-container {
    margin: 50px var(--main-margin);
}

.departments-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.department {
    padding: .5em;
    overflow: hidden;
}

.department div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}